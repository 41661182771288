<template>
  <div>
    <div v-if="pageDetailsList.data">
    <h1 class="main-title">{{ pageDetailsList.data.title }}</h1>
    
    <div
            :inner-html.prop="pageDetailsList.data.description.replace('/files/', 'https://cdn.tavguvenlik.com/files/')"
          ></div>
          
    </div>
  </div>
</template>

<script>
    import ContentService from '@/services/ContentService.js';

export default {
  data() {
    return {
      pageDetailsList : [],
      pageSlug: this.$route.params.slug
    };

  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    pageDetailSlug: {
      type: String,
      required: true,
    },
  },
 /* created() {

     return this.$apiClient
      .get("/staticpages/categorized/" +
          this.$route.params.pageDetailSlug +
          "?application=" +
          this.$application +
          "&language=" +
          this.$t("languageCode")
      )
      .then((res) => (this.pageDetailsList = res.data))
      .catch((error) => console.log("There was an error:" + error))
    
  },*/
  created() {
    ContentService.getPageDetail(this.$route.params.pageDetailSlug, this.$application, this.$t("languageCode"))
      .then((res) => (this.pageDetailsList = res.data))
      .catch((error) => console.log("There was an error:" + error));
  },
  computed: {
  },
  methods: {}
};
</script>

<style></style>
